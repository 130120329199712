import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Error = () => {

  return (
    <Layout>
      <Seo />
      <div className="wrapper">
        <h2 className="section-headline">Oops! Page not found!</h2>
      </div>
    </Layout>
  )
}

export default Error
